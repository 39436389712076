<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">
        <app-i18n code="home.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/kostentraeger' }">
        <app-i18n code="entities.kostentraeger.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <app-i18n code="entities.kostentraeger.edit.title" v-if="isEditing"></app-i18n>
        <app-i18n code="entities.kostentraeger.new.title" v-if="!isEditing"></app-i18n>
      </el-breadcrumb-item>
    </el-breadcrumb>

    <div class="app-content-page">
      <h1 class="app-content-title">
        <app-i18n code="entities.kostentraeger.edit.title" v-if="isEditing"></app-i18n>
        <app-i18n code="entities.kostentraeger.new.title" v-if="!isEditing"></app-i18n>
      </h1>

      <div class="app-page-spinner" v-if="findLoading" v-loading="findLoading"></div>
      
      <app-kostentraeger-form
        :isEditing="isEditing"
        :record="record"
        :saveLoading="saveLoading"
        @cancel="doCancel"
        @submit="doSubmit"
        v-if="!findLoading"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import KostentraegerForm from '@/modules/kostentraeger/components/kostentraeger-form';
import { routerAsync } from '@/app-module';

export default {
  name: 'app-kostentraeger-form-page',

  props: ['id'],

  components: {
    [KostentraegerForm.name]: KostentraegerForm,
  },

  computed: {
    ...mapGetters({
      record: 'kostentraeger/form/record',
      findLoading: 'kostentraeger/form/findLoading',
      saveLoading: 'kostentraeger/form/saveLoading',
    }),

    isEditing() {
      return !!this.id;
    },
  },

  async created() {
    if (this.isEditing) {
      await this.doFind(this.id);
    } else {
      await this.doNew();
    }
  },

  methods: {
    ...mapActions({
      doFind: 'kostentraeger/form/doFind',
      doNew: 'kostentraeger/form/doNew',
      doUpdate: 'kostentraeger/form/doUpdate',
      doCreate: 'kostentraeger/form/doCreate',
    }),

    doCancel() {
      routerAsync().push('/kostentraeger');
    },

    async doSubmit(payload) {
      if (this.isEditing) {
        return this.doUpdate(payload);
      } else {
        return this.doCreate(payload.values);
      }
    },
  },
};
</script>

<style>
</style>
